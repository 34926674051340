@use './styles/variables'
@use './styles/fonts'
@use './styles/icons'

*
  margin: 0
  padding: 0
  border: 0
  border-style: solid
  object-fit: contain
  text-decoration: none
  font-family: var(--font)
  -webkit-tap-highlight-color: transparent
  color: var(--yankees-blue)
  box-sizing: border-box
  background: transparent

ul, ol
  list-style: none

html
  height: -webkit-fill-available
  scroll-behavior: smooth

body, #root
  height: 100%
  height: stretch
  height: -moz-available
  height: -webkit-fill-available
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

input
  outline: none

button:not(:disabled)
  cursor: pointer

*::-webkit-scrollbar-track
  background-color: var(--gray-5) !important
*::-webkit-scrollbar
  width: 5px !important
*::-webkit-scrollbar-thumb
  border-radius: 50px !important
  background-color: var(--gray-60) !important

.container
  width: 100%
  height: 100vh
  max-height: 100vh
  display: flex
  .routerContainer
    width: calc( 100% - var(--left-menu-width))