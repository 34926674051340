@use 'src/styles' as *

.wrapper
  width: 100%
  display: flex
  flex-direction: column
  .title
    @extend %Bold-H4
    margin-bottom: 16px
  .skus
    width: 100%
    height: 100%
    flex: 1
    margin-top: 24px
    overflow-y: auto
    overflow-x: hidden
    border-radius: 6px
    background-color: var(--gray-5)
    border: 1px solid var(--gray-60)
    transition: 0.3s
    padding: 16px
    display: flex
    flex-wrap: wrap
    align-content: flex-start
    justify-content: space-between
    gap: 8px
    &.disabled
      background-color: var(--gray-25)
      .card
        opacity: 0.5
        .icon:hover
          cursor: default
    .card
      display: flex
      column-gap: 8px
      height: 48px
      width: calc( (100% / 2 ) - 4px)
      align-items: center
      box-sizing: border-box
      padding: 0 8px
      border-radius: 8px
      border: 1px solid var(--gray-60)
      background-color: var(--white)
      +fadeInAnimation(0.5s)
      transition: 0.3s
      &:hover
        box-shadow: 0px 0px 10px -5px rgba(63, 95, 72, 0.56)
        cursor: default
      .img
        width: 32px
        height: 32px
      .text
        @extend %Regular-Body
        flex: 1
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
      .icon:hover
        cursor: pointer
