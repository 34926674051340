@use 'src/styles' as *

.wrapper
  padding: 0 var(--primary-space)
  width: 100%
  border-radius: 4px
  box-sizing: border-box
  column-gap: 8px
  display: flex
  justify-content: center
  align-items: center
  transition: 0.3s
  @extend %Bold-Body
  &.primary
    border: 1px solid var(--green)
    background-color: var(--green)
    color: var(--white)
    +svgColor(var(--white))
    &:disabled
      border-color: var(--gray-100)
      background-color: var(--gray-100)
  &.secondary
    background-color: var(--white)
    border: 1px solid var(--dark-blue-gray)
    color: var(--dark-blue-gray)
    +svgColor(var(--dark-blue-gray))
    &:disabled
      border-color: var(--gray-60)
      opacity: 0.5
  &.small
    height: 32px
    @extend %Bold-Label
  &.medium
    height: 40px
  &.large
    height: 48px
  &:hover
    &:not(:disabled)
      cursor: pointer
      opacity: 0.8
  &.fit
    width: fit-content
  &.blocked
    background-color: var(--gray-60)