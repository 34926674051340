@use 'src/styles' as *

.wrapper
  +PopUpStepAnimation(0.3s)
  position: absolute
  top: 20px
  right: 0
  min-width: 270px
  max-height: 250px
  overflow-y: auto
  background-color: var(--white)
  z-index: 99999
  filter: drop-shadow(0px 2px 16px rgba(43, 40, 69, 0.06))
  border-radius: 4px
  padding: 4px 0
  cursor: default
  .item
    display: flex
    height: 48px
    align-items: center
    padding: 0 16px
    column-gap: 8px
    .text
      +textLinesLimit(1)
      @extend %Regular-Body
    .img
      height: 32px
      width: 32px
