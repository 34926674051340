@use 'src/styles' as *

.wrapper
  height: 176px
  width: 100%
  background-color: var(--white)
  display: flex
  align-items: center
  border: 1px solid var(--gray-60)
  border-radius: 8px
  cursor: pointer
  column-gap: 24px
  padding: 24px 59px 24px 24px
  transition: 0.3s background-color
  &.active
    background-color: var(--green)
    border-color: transparent
    cursor: default
    .icon
      +svgColor(var(--white))
    .right
      .title, .text
        color: var(--white)
  &:not(.active, .disabled):hover
    box-shadow: 0px 0px 10px -5px rgba(63, 95, 72, 0.56)
  &.disabled
    background-color: var(--gray-25)
    cursor: not-allowed
  .icon
    width: 128px
    height: 128px
    +svgColor(var(--dark-blue-gray))
  .right
    display: flex
    flex-direction: column
    justify-content: center
    .title
      @extend %Bold-H3
      color: var(--dark-blue-gray)
      margin-bottom: 16px
    .text
      color: var(--dark-blue-gray)
      @extend %Regular-Body
      +textLinesLimit(4)
