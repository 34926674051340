@use 'src/styles' as *

.wrapper
  +fadeInAnimation(0.5s)
  padding: 16px
  width: 328px
  border-radius: 4px
  position: relative
  background-color: var(--white)
  display: flex
  flex-direction: column
  row-gap: 8px
  .top
    display: flex
    height: 24px
    justify-content: space-between
    .title
      @extend %Bold-H4
      flex: 1
      +textLinesLimit(1)
  .text
    width: 100%
    @extend %Regular-Body
  .bottom
    display: flex
    align-items: center
    .steps
      flex: 1
      display: flex
      column-gap: 6px
      .step
        border-radius: 100%
        width: 6px
        height: 6px
        background-color: var(--gray-60)
        &.active
          background-color: var(--green)
    .backButton, .nextButton
      width: 32px
      height: 32px
      border-radius: 100%
      background-color: var(--gray-5)
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      margin-left: 16px
      &:hover
        opacity: 0.8
    .nextButton
      background-color: var(--green)
.triangle
  position: absolute
  width: 0
  height: 0
  border: 8px solid transparent
  &.bottom
    border-top: 10px solid var(--white)
    bottom: -18px
    &.center
      margin: 0 auto
      left: 0
      right: 0
  &.right
    border-left: 10px solid var(--white)
    right: -18px
    &.center
      margin: auto 0
      top: 0
      bottom: 0
  &.top
    border-bottom: 10px solid var(--white)
    top: -24px
    &.center
      margin: 0 auto
      left: 0
      right: 0
  &.left
    border-right: 10px solid var(--white)
    left: -18px
    &.center
      top: 0
      bottom: 0
      margin: auto 0
