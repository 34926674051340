@use 'src/styles' as *

.container
  +fadeInAnimation(0.5s)
  margin: 8px 24px
  flex: 1
  .title
    font-weight: 700
    font-size: 40px
    line-height: 48px
    padding: 8px 0
