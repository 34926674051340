@use 'src/styles' as *

.container
  +fadeInAnimation(0.5s)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  row-gap: 24px
  .image
    width: 160px
    height: 130px
  .content
    display: flex
    flex-direction: column
    align-items: center
    row-gap: 8px
    .title
      text-align: center
      @extend %Bold-H4
    .text
      @extend %Regular-Subtitle
      text-align: center
