\:root
  --font: "Causten Round", "causten", Arial, Helvetica, system-ui, sans-serif

  --white: #FFFFFF
  --ghost-white: #FAFBFC

  --extraordinaria: #FFEDD4

  --gray-200: #A2A6BD
  --gray-300: #8084A2
  --gray-100: #C6C9D8
  --gray-60: #DDDFE8
  --gray-25: #EBEDF1
  --gray-5: #F9F9FA
  --dark-blue-gray: #666A8F

  --yankees-blue: #2B2845
  --blue-de-france: #3584E0
  --blue-jeans: #56B0F3
  --sonadora: #66A0D6
  --diferente: #31549E

  --green: #00B56B
  --green-10: #E5F8F0
  --philippine-green: #01834E
  --cercana: #5EBD78
  --productiva: #37723D

  --red-10: #FFE6EB

  --chiper-brand-red: #FF0136
  --transformadora: #A12822

  --positiva: #EF909B
  --creativa: #7255A2

  --bright-yellow: #FF9F26
  --visionaria: #F4AA1C

  --transparent-1: rgba(43, 40, 69, 0.4)
  --transparent-2: rgba(255, 255, 255, 0.2)
  --transparent-3: rgba(0, 0, 0, 0.7)
  --transparent-4: rgba(243, 240, 240, 0.275)

  --ob-cover-background: var(--transparent-1)

  --ob-cover-z-index: 99998
  --ob-tooltip-z-index: 99999
  --window-cover-z-index: 9999999

  --primary-space: 16px
  --primary-total-width: calc( 100% - ( var(--primary-space) * 2 ))
  --primary-total-height: var(--primary-total-width)

  --regular-font-size: 16px

  --left-menu-width: 70px
