@use 'src/styles' as *

.wrapper
  display: flex
  flex-direction: column
  .title
    @extend %Bold-H4
    margin-bottom: 24px
  .dateRange
    display: flex
    gap: 24px
    margin-bottom: 24px
  .time
    display: flex
    gap: 24px
    margin-bottom: 32px
  .week
    display: flex
    gap: 24px
    .left
      .text
        @extend %Small-2
        margin-bottom: 24px
    .right
      .text
        @extend %Small-2
        margin-bottom: 24px
      .frequency
        box-sizing: border-box
        width: 310px
        height: 50px
        padding: 13px 15px
        display: flex
        justify-content: space-between
        border: 1px solid var(--gray-60)
        border-radius: 4px
        &.disabled
          background: var(--gray-25)
        .day
          @extend %Regular-Medium-Body
          border-radius: 50%
          border: 1px solid var(--gray-60)
          width: 24px
          height: 24px
          display: flex
          align-items: center
          justify-content: center
          color: var(--gray-200)
          cursor: pointer
          &.disabled
            cursor: default
          &.selected
            border: 1px solid var(--green)
            color: var(--green)