@use 'src/styles' as *

.container
  +fadeInAnimation(0.3s)
  grid-template-columns: 1fr 1fr
  display: grid
  column-gap: 24px
  margin-bottom: 36px
  .infos
    display: flex
    column-gap: 53px