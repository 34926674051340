@use 'src/styles' as *

.wrapper
  display: flex
  padding-right: 118px
  flex-direction: column
  .title
    @extend %Bold-H4
    margin-bottom: 56px
  .cards
    display: grid
    row-gap: 32px
