@use 'src/styles' as *

.wrapper
  width: 100%
  height: 52px
  display: flex
  align-items: center
  border: 1px solid var(--gray-60)
  background-color: var(--white)
  border-radius: 4px
  padding: 0 var(--primary-space)
  position: relative
  transition: 0.3s
  &:not(:focus-within)
    .results:not(:hover)
      display: none
  &.disabled
    background-color: var(--gray-25)
    .icon, .input
      color: var(--gray-200)
  .icon, .closeIcon
    width: 16px
    height: 16px
    font-size: 14px
    display: flex
    justify-content: center
    align-items: center
  .icon
    font-weight: 700
    margin-right: 8px
  .closeIcon
    margin-left: 8px
    cursor: pointer
    @include turnInAnimation(-90deg, 180deg)
  .input
    flex: 1
    transition: 0.3s
    height: 100%
    @extend %Regular-Body
    &::placeholder
      color: var(--gray-200)

  .results
    flex-direction: column
    padding: 0 0 8px 0
    position: absolute
    top: 44px
    left: -1px
    width: calc(100% + 2px)
    border: 1px solid var(--gray-60)
    border-top: none
    background-color: var(--white)
    border-radius: 0 0 4px 4px
    max-height: 140px
    overflow-y: auto
    @include fadeInAnimation(0.3s)
    z-index: 1000000
    .divider
      background-color: var(--gray-25)
      height: 1px
    .item
      padding: 0 16px
      display: flex
      align-items: center
      margin: 8px 0
      column-gap: 8px
      cursor: pointer
      transition: 0.5s
      .name
        transition: 0.3s
        @extend %Regular-Body
      &:hover
        background-color: var(--gray-5)
      .img
        height: 32px
        width: 32px
    .empty
      padding: 0 16px
      margin: 16px 0 8px
      @extend %Regular-Body
