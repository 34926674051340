@use 'src/styles' as *

.table
  width: 100%
  margin-top: 32px
  height: calc( 100vh - 220px )
  overflow: auto
  .emptyState
    height: calc(100% - 200px)
    min-height: 240px
  .row
    +fadeInAnimation(0.3s)
    display: grid
    grid-template-columns: minmax(60px, 0.5fr) minmax(180px, 1.5fr) minmax(210px, 1.5fr) minmax(90px, 0.5fr) minmax(130px, 1fr) minmax(90px, 0.75fr) minmax(170px, 1.25fr) repeat(2, minmax(110px, 1fr))
    .head, .cell
      display: flex
      align-items: center
      justify-content: center
      text-align: center
      height: 54px
      border-left: 1px solid var(--gray-60)
      column-gap: 8px
      word-break: break-all
      text-overflow: ellipsis
      &:first-child
        border: none
      .icon
        cursor: pointer
        position: relative
        &.disabled
          cursor: default
          +svgColor(var(--gray-60))
    .head
      font-weight: 400
      letter-spacing: -0.1px
      font-size: 18px
      background-color: var(--gray-25)
      transition: 0.3s
      &.active
        background-color: var(--green-10)
        .icon
          +svgColor(var(--green))
    .cell
      @extend %Regular-Body
      position: relative
      border-color: var(--gray-25)
    .dates
      display: flex
      flex-direction: column
      .countDate
        color: var(--dark-blue-gray)
    .actions
      column-gap: 24px
