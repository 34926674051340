@use 'src/styles' as *

.container
  width: 100%
  height: 100vh
  overflow: hidden
  background: transparent
  position: fixed
  top: 0
  left: 0
  z-index: 9999999999
  .tooltip
    position: fixed
    transition: 0.5s
    &.menu
      left: calc(70px + 16px)
      top: calc(50% - 164px / 2)
    &.title
      top: calc(85px + 16px)
      left: calc(50% - 328px / 2)
    &.button
      left: calc(100% - 328px - 24px)
      top: 185px
    &.searchbar
      left: calc(70px + 24px)
      top: 185px
    &.table
      top: 275px
      left: calc(50% - 328px / 2)
.modal
  display: flex
  flex-direction: column
  align-items: center
  row-gap: 24px
  .image
    width: 160px
    height: 118px
    margin-top: 48px
  .title
    @extend %Bold-H4
    margin-bottom: 8px
  .text
    @extend %Regular-Subtitle
    text-align: center
