@use 'src/styles' as *

.container
  height: 100vh
  width: 100%
  position: fixed
  top: 0
  left: 0
  z-index: 9999999999999999
  display: flex
  align-items: center
  justify-content: center
  animation: bg 0.3s linear 0.5s both
  @keyframes bg
    0%
      background-color: transparent
    100%
      background-color: var(--transparent-1)
  .modal
    +fadeInAnimation(0.5s)
    +animationDelay(0.5s)
    background-color: var(--white)
    width: 448px
    max-height: 400px
    border-radius: 4px
    overflow-y: auto
    padding: 24px
    position: relative
    .close
      position: absolute
      cursor: pointer
      right: 24px
      top: 24px
