@mixin popUpAnimation($duration)
  animation: pupUp $duration ease-out
  @keyframes pupUp
    from
      transform: scale(0)
      opacity: 0
    to
      opacity: 1

@mixin fadeInAnimation($duration)
  animation: fadeIn $duration ease-in-out
  @keyframes fadeIn
    from
      opacity: 0
    to
      opacity: 1

@mixin dropDownAnimation($duration, $height)
  animation: dropDown $duration ease
  @keyframes dropDown
    from
      height: 0
      overflow: hidden
    to
      overflow: hidden
      height: $height

@mixin turnAnimation($from, $to)
  animation: turn 0.7s ease
  @keyframes turn
    from
      transform: scale(1.001) rotate($from)
    to
      transform: scale(1.001) rotate($to)

@mixin turnInAnimation($from, $to)
  animation: turnIn 0.7s ease
  @keyframes turnIn
    from
      transform: rotate($from)
    to
      transform: rotate($to)

@mixin stepAnimation($duration)
  animation: step $duration ease-in
  @keyframes step
    0%
      transform: scale(1)
    50%
      transform: scale(1.2)

@mixin fromLeftAnimation($duration, $translate)
  animation: fromLeft $duration ease-out
  @keyframes fromLeft
    from
      opacity: 0
      transform: translateX(-$translate)
    to
      opacity: 1

@mixin fromRightAnimation($duration, $translate)
  animation: fromRight $duration ease-out
  @keyframes fromRight
    from
      opacity: 0
      transform: translateX($translate)
    to
      opacity: 1

@mixin fromTopAnimation($duration, $translate)
  animation: fromtop $duration ease-out
  @keyframes fromtop
    from
      opacity: 0
      transform: translateY(-$translate)
    to
      opacity: 1

@mixin PopUpStepAnimation($duration)
  animation: popUpStep $duration ease
  @keyframes popUpStep
    0%
      transform: scale(0)
      opacity: 0
    70%
      transform: scale(1.1)
    100%
      opacity: 1

@mixin fromBottomAnimation($duration, $translate)
  animation: frombottom $duration ease-out
  @keyframes frombottom
    from
      opacity: 0
      transform: translateY(+$translate)
    to
      opacity: 1

@mixin pulseSkeletonAnimation($duration1, $duration2)
  animation: pulse $duration1 linear $duration2 both infinite
  @keyframes pulse
    20%
      opacity: 1
    50%
      opacity: 0.5
    80%
      opacity: 1

@mixin animationDelay($duration)
  animation-delay: $duration
  animation-fill-mode: both

@mixin fromHorizontalAnimation($duration, $translate, $debounce, $timming)
  animation: fromAnimation $duration $timming
  @keyframes fromAnimation
    0%
      opacity: 0
      transform: translateX($translate)
    75%
      opacity: 1
      transform: translateX($debounce)

@mixin fromVerticalAnimation($duration, $translate, $debounce, $timming)
  animation: fromAnimation $duration $timming
  @keyframes fromAnimation
    0%
      opacity: 0
      transform: translateY($translate)
    75%
      opacity: 1
      transform: translateY($debounce)