@use 'src/styles' as *

.container
  +fadeInAnimation(0.3s)
  grid-template-columns: 1fr 1fr
  display: grid
  column-gap: 94px
  width: 100%
  margin-bottom: 48px
  .left
    display: flex
    flex-direction: column
  .right
    display: grid
    grid-template-rows: auto 1fr

