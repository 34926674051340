@use 'src/styles' as *

.wrapper
  margin-bottom: 46px
  .title
    @extend %Bold-H4
    margin-bottom: 24px
  .player
    height: 370px
    width: 100%
    background-color: var(--yankees-blue)
    border-radius: 8px
    border: 28px solid var(--gray-100)
    box-shadow: 0px 0px 0px 1px var(--dark-blue-gray) inset
