@use 'src/styles' as *

.wrapper
  width: 100%
  row-gap: 16px
  display: flex
  flex-direction: column
  .title
    @extend %Bold-H4
  .wordCount
    @extend %Bold-Label
    display: flex
    justify-content: flex-end
    margin: 4px 16px 0 16px
    color: var(--gray-200)