@use 'src/styles' as *

.wrapper
  display: flex
  flex-direction: column
  gap: 48px
  width: 100%
  .form
    display: flex
    flex-direction: column
    gap: 24px
    .title
      @extend %Bold-H4