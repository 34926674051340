$base: '../assets/fonts'

@font-face
  font-family: 'icomoon'
  src: url('#{$base}/icomoon.eot') format('embedded-opentype'), url('#{$base}/icomoon.ttf') format('truetype'), url('#{$base}/icomoon.woff') format('woff'), url('#{$base}/icomoon.svg') format('svg')
  font-style: normal
  font-weight: normal
  font-display: block

[class^="icon-"], [class*=" icon-"]
  font-family: 'icomoon' !important
  speak: never
  font-style: normal
  font-weight: 700
  font-variant: normal
  text-transform: none
  line-height: 1
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.icon-thin-caret-right:before
  content: "\e900"
  
.icon-thin-caret-left:before
  content: "\e901"
  
.icon-thin-caret-up:before
  content: "\e902"
  
.icon-thin-caret-down:before
  content: "\e903"
  
.icon-thin-funnel-simple:before
  content: "\e904"
  
.icon-thin-plus:before
  content: "\e905"

.icon-thin-x:before
  content: "\e906"

.icon-thin-user-list:before
  content: "\e907"

.icon-thin-list-numbers:before
  content: "\e908"

.icon-thin-sign-out:before
  content: "\e909"

.icon-thin-megaphone:before
  content: "\e90a"

.icon-thin-calendar-blank:before
  content: "\e90b"

.icon-thin-map-pin-line:before
  content: "\e90c"

.icon-thin-info:before
  content: "\e90d"

.icon-thin-clock-clock-wise:before
  content: "\e90e"

.icon-thin-clock-afternoon:before
  content: "\e90f"

.icon-thin-clock:before
  content: "\e910"

.icon-thin-chart-line-up:before
  content: "\e911"

.icon-fill-warning:before
  content: "\e912"

.icon-fill-check-circle:before
  content: "\e913"

.icon-fill-x-circle:before
  content: "\e914"

.icon-fill-arrows-down-up:before
  content: "\e915"
  font-weight: 400

.icon-light-download-simple:before
  content: "\e916"

.icon-light-pencil-simple:before
  content: "\e917"

.icon-thin-magnifying-glass:before
  content: "\e918"
