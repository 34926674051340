@use 'src/styles' as *

.container
  @include fadeInAnimation(0.5s)
  padding: 20px 24px
  width: 100%
  .head
    font-weight: 700
    font-size: 40px
    line-height: 48px
