@use 'src/styles' as *

.container
  width: 100%
  display: flex
  flex-direction: column
  .wrapper
    width: 100%
    height: 76px
    display: flex
    flex-direction: column
    justify-content: center
    border-radius: 4px
    border: 1px solid var(--gray-60)
    background-color: var(--ghost-white)
    overflow: hidden
    transition: 0.3s
    padding: 8px 16px
    &:focus-within
      border-color: var(--yankees-blue)
      .label
        display: block
      .input
        &::placeholder
          color: transparent
    &.error
      border-color: var(--chiper-brand-red)
    &.disabled
      background-color: var(--gray-25)
      .label
        color: var(--gray-200)
      .input
        color: var(--gray-200)
        &::placeholder
          color: var(--gray-300)
    .label
      display: none
      @include fadeInAnimation(0.3s)
      @extend %Regular-Subhead
      color: var(--dark-blue-gray)
      &.visible
        display: block
    .input
      @extend %Regular-Body
      background-color: transparent
      border: none
      overflow: auto
      outline: none
      -webkit-box-shadow: none
      -moz-box-shadow: none
      box-shadow: none
      resize: none
      text-align: left
      flex: 1
      &::placeholder
        color: var(--gray-200)
  .helperText, .errorMessage, .count
    @extend %Regular-Label
    margin: 4px 16px 0 16px
    @include fadeInAnimation(0.3s)
  .helperText
    display: flex
    color: var(--dark-blue-gray)
  .errorMessage
    color: var(--chiper-brand-red)
  .count
    @extend %Bold-Label
    color: var(--gray-200)
    align-self: flex-end
