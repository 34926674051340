.loader
  position: fixed
  height: 100vh
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 9999999999999999
  background-color: var(--transparent-4)
  display: flex
