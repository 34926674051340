@use 'src/styles' as *

.fixedwrapper
  min-width: var(--left-menu-width)
  width: var(--left-menu-width)
  height: 100vh
  z-index: 9999999
  .wrapper
    width: 70px
    height: 100%
    position: fixed
    +fromHorizontalAnimation(0.5s, -100px, 5px, ease-in-out)
    box-shadow: 0px 2px 16px rgba(43, 40, 69, 0.06)
    background-color: var(--white)
    display: flex
    flex-direction: column
    transition: 0.3s
    &:hover
      width: 255px
      .imageWrapper
        justify-content: flex-start
        padding-left: 25px
        .imageC
          display: none
        .image
          display: block
      .menuItem
        padding: 0 24px
        column-gap: 16px
        &.active
          +svgColor(var(--dark-blue-gray))
        .name
          display: flex
          flex: 1
          +textLinesLimit(1)
          color: var(--dark-blue-gray)
      .user
        .userInfo
          display: flex
        .avatar
          display: none
    .imageWrapper
      width: 100%
      height: 70px
      display: flex
      align-items: center
      justify-content: center
      .imageC
        height: 27px
        width: 17px
        +fadeInAnimation(0.5s)
      .image
        display: none
        width: 68px
        height: 25px
    .center
      flex: 1
      display: flex
      flex-direction: column
    .menuItem, .user
      width: 100%
      height: 46px
      display: flex
      padding-left: 24px
      align-items: center
      cursor: pointer
      transition: 0.3s
      border-left: 2px solid transparent
      +svgColor(var(--dark-blue-gray))
      &.active
        background-color: var(--gray-25)
        box-sizing: border-box
        border-left: 2px solid var(--chiper-brand-red)
        +svgColor(var(--chiper-brand-red))
        .name
          color: var(--chiper-brand-red)
      .name
        display: none
    .menuItem:hover
      background-color: var(--gray-5)
      &.active
        background-color: var(--gray-25)
    .user
      overflow: hidden
      padding-left: 16px
      height: 64px
      border-top: 1px solid var(--gray-25)
      img
        width: 100%
      .userInfo
        display: none
