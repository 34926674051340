@use 'src/styles' as *

.container
  position: relative
  width: 100%
.wrapper
  width: 100%
  display: flex
  padding: 0 16px
  align-items: center
  height: 50px
  border: solid 1px var(--gray-60)
  background-color: var(--ghost-white)
  column-gap: 8px
  border-radius: 4px
  cursor: pointer
  .input
    width: 100%
    transition: 0.3s
    .label
      @extend %Regular-Body
      color: var(--gray-200)
      transition: 0.3s
      &.top
        @extend %Regular-Subhead
        color: var(--dark-gray-blue)
    .value
      @extend %Regular-Body
      +fadeInAnimation(0.3s)
  &.focus
    background-color: var(--white)
    border-color: var(--yankees-blue)
    .input
      height: 34px
  &.disabled
    cursor: default
    background-color: var(--gray-25)
    .label
      color: var(--gray-300)
      &.top
        color: var(--gray-300)
    .value
      color: var(--gray-300)
.picker
  position: absolute
  z-index: 9999999
  top: 50px
.readOnly
  cursor: default