@use 'src/styles' as *

.wrapper
  display: flex
  flex-direction: column
  gap: 24px
  width: 100%
  .title
    @extend %Bold-H4
  .dateFields
    display: flex
    flex-direction: column
    gap: 16px
    .input
      display: flex
      padding: 0 16px
      align-items: center
      height: 50px
      border: solid 1px var(--gray-60)
      column-gap: 8px
      border-radius: 4px
      cursor: default
      background-color: var(--gray-25)
      .label
        @extend %Regular-Body
        color: var(--gray-300)
  .switch
    display: flex
    justify-content: space-between
    .switchTitle
      @extend %Regular-Subtitle
  .weekDays
    display: flex
    flex-direction: column
    gap: 16px
    .dayTitle
      @extend %Regular-Body
    .daysList
      display: flex
      gap: 8px
  .week
    margin: -8px 0
    display: flex
    flex-direction: column
    gap: 16px
    .weekTitle
      @extend %Regular-Body
    .weekSelect
      position: relative
      width: 100%
      .icon
        position: absolute
        right: 17px
        top: 17px
    .select
      display: flex
      padding: 0 16px
      justify-content: space-between
      align-items: center
      height: 50px
      border: solid 1px var(--gray-60)
      border-radius: 4px
      cursor: default
      background-color: var(--gray-25)
      .label
        @extend %Regular-Body
        color: var(--gray-300)
