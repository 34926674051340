@use 'src/styles' as *

.container
  padding: 24px
  width: 100%
  display: flex
  flex-direction: column
  overflow-y: auto
  .title
    margin: 24px 0
    height: 52px
    @extend %Bold-H3
    color: var(--dark-blue-gray)
    display: flex
    align-items: center
    column-gap: 24px
    .icon
      transition: 0.3s
      &:hover
        cursor: pointer
        animation: float 1s ease-in infinite

@keyframes float
  0%
    transform: scale(1.1) translateX(0px)
  50%
    transform: scale(1.1) translateX(-10px)
  100%
    transform: scale(1.1) translateX(0px)
