@use 'src/styles' as *

.container
  width: 100%
  display: grid
  grid-template-columns: 1fr 1fr
  overflow: hidden
  .left
    width: 100%
    position: relative
    background-position: center
    background-size: cover
    display: flex
    align-items: center
    justify-content: center
    +fromLeftAnimation(0.5s, 50%)
    .logo
      z-index: 99
      width: 32%
      +PopUpStepAnimation(0.7s)
      +animationDelay(0.5s)
    .ownerWrapper
      position: fixed
      height: 70%
      width: 20%
      z-index: 99
      bottom: -15px
      right: 5.5%
      left: 0
      margin: auto
      +fadeInAnimation(0.3s)
      +animationDelay(1s)
      .owner
        height: 100%
        width: 100%
  .right
    +fadeInAnimation(0.5s)
    justify-self: center
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 327px
    .texts
      display: grid
      justify-items: center
      row-gap: 16px
      margin-bottom: 48px
      .title
        @extend %Bold-H1
        letter-spacing: -1px
      .subTitle
        @extend %Regular-H4
    .google
      width: 20px
      height: 20px
