@use 'src/styles' as *

.wrapper
  @include fromVerticalAnimation(0.5s, -120px, 20px, ease)
  height: 68px
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  .item
    display: flex
    align-items: center
    .step
      transition: 0.3s
      width: 20px
      height: 20px
      display: flex
      align-items: center
      justify-content: center
      background-color: var(--gray-25)
      color: var(--dark-blue-gray)
      margin-right: 8px
      border-radius: 100%
      font-size: 12px
    .name
      @extend %Regular-Medium-Body
      transition: 0.5s
      color: var(--dark-blue-gray)
    .slash
      height: 2px
      width: 42px
      background-color: var(--gray-25)
      transition: 1.5s
      margin: 0 24px
    &.active
      .step, .slash
        background-color: var(--green)
        color: var(--white)
      .step
        @include stepAnimation(0.3s)
      .name
        color: var(--yankees-blue)

