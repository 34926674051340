@use 'src/styles' as *

.wrapper
  +fadeInAnimation(0.3s)
  margin-top: 16px
  padding-left: 36px
  display: flex
  flex-direction: column
  row-gap: 32px
  flex: 1
  .form
    display: flex
    column-gap: 24px
    width: 100%
  .serialsCard
    width: 100%
    flex: 1
    max-height: 270px
    overflow-y: auto
    border-radius: 6px
    background-color: var(--gray-5)
    border: 1px solid var(--gray-60)
    transition: 0.3s
    padding: 16px
    display: flex
    flex-wrap: wrap
    align-content: flex-start
    gap: 16px
    .card
      display: flex
      column-gap: 8px
      height: 24px
      width: fit-content
      align-items: center
      box-sizing: border-box
      padding: 0 8px
      border-radius: 4px
      background-color: var(--gray-25)
      +PopUpStepAnimation(0.5s)
      transition: 0.3s
      &:hover
        box-shadow: 0px 0px 10px -5px rgba(63, 95, 72, 0.56)
        cursor: default
      &.error
        background-color: var(--red-10)
        &:hover
          box-shadow: 0px 0px 10px -5px rgba(255, 117, 117, 0.56)
      .text
        @extend %Regular-Label
        flex: 1
      .icon:hover
        cursor: pointer
