@use 'src/styles' as *

.wrapper
  width: 100%
  margin-top: 32px
  height: calc( 100vh - 310px )
  overflow: auto
  .row
    +fadeInAnimation(0.3s)
    display: grid
    grid-template-columns: minmax(724px, 2fr) minmax(169px, 0.3fr) minmax(162px, 0.3fr) minmax(193px, 0.3fr)
    .head, .cell
      display: flex
      align-items: center
      justify-content: center
      text-align: center
      height: 54px
      border-left: 1px solid var(--gray-60)
      column-gap: 8px
      &:first-child
        border: none
        justify-content: flex-start
        padding-left: 24px
      .icon
        cursor: pointer
        position: relative
        &.disabled
          cursor: default
          +svgColor(var(--gray-60))
    .head
      font-weight: 400
      letter-spacing: -0.1px
      font-size: 18px
      background-color: var(--gray-25)
      transition: 0.3s
      &.active
        &:nth-child(3)
          background-color: var(--green-10)
          .icon
            +svgColor(var(--green))
    .cell
      @extend %Regular-Body
      position: relative
      border-color: var(--gray-25)
    .actions
      column-gap: 24px
