@use 'src/styles' as *

.wrapper
  display: flex
  flex-direction: column
  width: calc( 100% - 12px )
  justify-self: flex-end
  .title
    @extend %Bold-H4
    margin-bottom: 16px
  .text
    @extend %Regular-Body
    margin-bottom: 124px
  .pickers
    display: flex
    column-gap: 24px  
    width: 100%