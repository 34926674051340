@use 'src/styles' as *

.wrapper
  display: flex
  flex-direction: column
  .top, .center
    padding-left: 36px
    display: flex
    justify-content: space-between
    align-items: center
    height: 28px
    @extend %Regular-Label
    & > div
      margin: 0
    .title
      @extend %Bold-H4
  .example
    padding-left: 36px
    opacity: 0
    margin-top: 2px
    @extend %Regular-Medium-Body
    color: var(--dark-blue-gray)
    transition: 0.3s
    margin-bottom: 8px
    &.show
      opacity: 1
  .center
    height: 40px
    .total
      @extend %Bold-Caption-1
      .text
        @extend %Regular-Caption-1
