@use 'src/styles' as *

.wrapper
  +fadeInAnimation(0.3s)
  grid-template-columns: 1fr 1fr
  display: grid
  width: 100%
  height: 100%
  margin-bottom: 48px
  column-gap: 24px
  .left
    display: flex
    flex-direction: column
    margin-bottom: 120px
    .title
      @extend %Bold-H4
      margin-top: 24px
    .subtitle
      @extend %Regular-Subtitle
      margin: 24px 0 16px 0
    .inputWrapper
      width: 100%
      display: flex
      margin: 0 24px
      column-gap: 8px
      margin: 0 0 16px 0
  .right
    .subtitle
      @extend %Regular-Subtitle
      margin-top: 71.5px
      margin-bottom: 16px
  .submit
    position: fixed
    display: flex
    justify-content: flex-end
    bottom: 0
    right: 0
    padding: 8px 24px 32px 0
    width: 100%
    background-color: var(--white)
    .button
      width: 188px