.wrapper
  position: fixed
  min-height: calc( 56px - 32px )
  background-color: var(--yankees-blue)
  border-radius: 4px
  padding: var(--primary-space)
  display: flex
  align-items: center
  transition: 0.7s
  top: -70px
  opacity: 0
  gap: 8px
  z-index: 99999999
  width: fit-content
  left: 0
  right: 0
  margin: 0 auto
  &.show
    top: 40px
    opacity: 1
  .icon
    height: 24px
    width: 24px
    display: flex
    justify-content: center
    align-items: center
    font-size: 18px
    font-weight: 400
    &.success
      color: var(--green)
    &.alert
      color: var(--bright-yellow)
    &.error
      color: var(--chiper-brand-red)
  .message
    margin-top: 2px
    font-size: var(--regular-font-size)
    color: var(--white)
