@use 'src/styles' as *

.container
  +fadeInAnimation(0.5s)
  display: flex
  flex-direction: column
  width: 100%
  .tabs
    width: 420px
    margin: 16px 0 24px 0
    background-color: var(--white)
    .tab
      @extend %Regular-Body
      height: 52px
      display: flex
      align-items: center
      justify-content: center
      border-bottom: 2px solid transparent
      transition: 0.3s
      color: var(--dark-blue-gray)
      &.active
        border-color: var(--chiper-brand-red)
        color: var(--yankees-blue)
      &:hover
        background-color: var(--gray-5)
