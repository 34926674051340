@use 'src/styles' as *

.wrapper
  width: 363px
  padding: 16px
  display: flex
  flex-direction: column
  row-gap: 8px
  border-radius: 4px
  background-color: var(--white)
  +dropDownAnimation(0.3s, 265px)
  z-index: 9999999999
  .top
    text-transform: capitalize
    @extend %Bold-Body
    height: 20px
    display: flex
    justify-content: space-between
    align-items: center
    .icon
      cursor: pointer
      &:hover
        +svgColor(var(--green))
  .week
    width: 100%
    height: 32px
    display: flex
    align-items: center
    background-color: var(--gray-5)
    cursor: default
    .dayName
      @extend %Bold-Label
      color: var(--dark-blue-gray)
      height: 32px
      width: 47px
      display: flex
      justify-content: center
      align-items: center
  .calendar
    display: grid
    grid-template-columns: repeat(7, 1fr)
    .cell, .empty
      display: flex
      align-items: center
      justify-content: center
      height: 32px
      width: 47px
      .day
        transition: 0.3s background
        width: 28px
        height: 28px
        border-radius: 100%
        @extend %Regular-Label
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
        &:hover, &.today:hover
          background-color: rgba(162, 166, 189, 0.2)
        &.today
          color: var(--green)
          background-color: rgba(0, 181, 107, 0.2)
        &.selected
          color: var(--white)
          background-color: var(--green)
        &.disabled
          opacity: 0.9
          cursor: not-allowed
          &:hover
            background-color: var(--white)
    .empty
      cursor: default
      @extend %Regular-Label
      color: var(--gray-100)
