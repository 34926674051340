@use 'src/styles' as *

.wrapper
  .title
    @extend %Bold-H4
    margin-bottom: 32px
  .container
    display: flex
    flex-direction: column
    row-gap: 16px
    .option
      +fadeInAnimation(0.3s)
      height: 48px
      display: flex
      align-items: center
      padding-left: 14px
