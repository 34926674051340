.switch
  position: relative
  display: flex
  width: 48px
  height: 24px
.input
  opacity: 0
  width: 0
  height: 0
.slider
  position: absolute
  cursor: pointer
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: var(--gray-25)
  transition: 0.4s
  &:before
    position: absolute
    bottom: 4px
    left: 4px
    content: ""
    height: 16px
    width: 16px
    background-color: var(--white)
    transition: 0.4s
  .input:checked + &
    background-color: var(--green)
  .input:checked + &:before
    transform: translateX(24px)
  &.round
    border-radius: 20px
  &.round:before
    border-radius: 50%