@mixin svgColor($color)
  & svg *
    fill: $color

@mixin textLinesLimit($maxLines)
  overflow: hidden
  display: -webkit-box
  -webkit-line-clamp: $maxLines
  line-clamp: $maxLines
  -webkit-box-orient: vertical
